import dayjs from 'dayjs'

interface FiltersState {
	selectedDates: [string, string] | []
	selectedDeviceIds: string[]
	name: string
	selectedRole: number | null
	unapproved: boolean
	selectedLevel: number | null
	enabled: number
	username: string
	email: string
	customerId: number
	users: {
		username: string
		selectedLevel: number | null
		customerId: number
		enabled: number
		partnerId: number
	}
	appliances: {
		customerId: number
		resellerId: number
		solutionId: string
		deviceId: string
		model: string
		serial: string
		applianceStatus: string
		isDemo: string
	}
	requests: {
		customerId: number
		resellerId: number
		solutionId: string
		model: string
		serial: string
		solutionStatus: string
	}
	trustedFlows: {
		name: string
		sourceIP: string
		destinationIP: string
		port: string
		protocol: string
		customerId: number
		deviceIds: string[]
		siteId: number
	}
	ignoredFlows: {
		name: string
		sourceIP: string
		destinationIP: string
		port: string
		protocol: string
		customerId: number
		deviceIds: string[]
	}
	rules: {
		name: string
		sid: string
		group: string
		classType: string
		chapter: number | null
		category: number | null
		overrideId: number | null
		matchStatus: string
		onlyNew: boolean
		emptyDesc: boolean
		incidentCount: string
		protocol: string
	}
	overrides: {
		name: string
		chapter: number | null
		category: number | null
	}
	masterRules: {
		group: string
		classType: string
		chapter: number | null
		category: number | null
	}
	ips: {
		companyId: number | null
		devices: any[]
		sites: any[]
		groups: string[]
		ip: string
		dateMin: string
		dateMax: string
		vlan: number | null
	}
}

const savedCustomerId = localStorage.getItem('customerId') ? parseInt(localStorage.getItem('customerId')!) : 0

console.log('saved cid', savedCustomerId)

const filtersState: FiltersState = {
	selectedDates: [
		localStorage.getItem('date_from') || dayjs().format('YYYY-MM-DD'),
		localStorage.getItem('date_to') || dayjs().format('YYYY-MM-DD'),
	],
	selectedDeviceIds: [],
	name: '',
	selectedRole: null,
	unapproved: false,
	selectedLevel: null,
	username: '',
	enabled: 0,
	email: '',
	customerId: 0,
	users: {
		username: '',
		selectedLevel: null,
		customerId: 0,
		enabled: 0,
		partnerId: 0,
	},
	appliances: {
		customerId: 0,
		resellerId: 0,
		solutionId: '',
		deviceId: '',
		model: '',
		serial: '',
		applianceStatus: '',
		isDemo: '',
	},
	requests: {
		customerId: 0,
		resellerId: 0,
		solutionId: '',
		model: '',
		serial: '',
		solutionStatus: '',
	},
	trustedFlows: {
		name: '',
		sourceIP: '',
		destinationIP: '',
		port: '',
		protocol: '',
		customerId: 0,
		deviceIds: [],
		siteId: 0,
	},
	ignoredFlows: {
		name: '',
		sourceIP: '',
		destinationIP: '',
		port: '',
		protocol: '',
		customerId: 0,
		deviceIds: [],
	},
	rules: {
		name: '',
		sid: '',
		group: '',
		classType: '',
		chapter: null,
		category: null,
		overrideId: null,
		matchStatus: '',
		onlyNew: false,
		emptyDesc: false,
		incidentCount: '',
		protocol: '',
	},
	overrides: {
		name: '',
		chapter: null,
		category: null,
	},
	masterRules: {
		group: '',
		classType: '',
		chapter: null,
		category: null,
	},
	ips: {
		companyId: savedCustomerId,
		ip: '',
		devices: [],
		sites: [],
		groups: [],
		dateMin: '',
		dateMax: '',
		vlan: null,
	},
}

export const filters = {
	namespaced: true,
	state: filtersState,
	mutations: {
		setSelectedDates(state: FiltersState, dates: [string, string]) {
			localStorage.setItem('date_from', dates[0])
			localStorage.setItem('date_to', dates[1])
			state.selectedDates = dates
		},
		setSelectedDeviceIds(state: FiltersState, ids: string[]) {
			state.selectedDeviceIds = ids
		},
		setName(state: FiltersState, name: string) {
			state.name = name
		},
		setRoleId(state: FiltersState, id: number) {
			state.selectedRole = id
		},
		setUnapproved(state: FiltersState, val: boolean) {
			state.unapproved = val
		},
		setUsername(state: FiltersState, val: string) {
			state.username = val
		},
		setUserEnabled(state: FiltersState, val: number) {
			state.enabled = val
		},
		setSelectedLevel(state: FiltersState, val: number | null) {
			state.selectedLevel = val
		},
		setEmail(state: FiltersState, val: string) {
			state.email = val
		},
		setCustomerId(state: FiltersState, val: number) {
			state.customerId = val
		},
	},
	actions: {
		setSelectedDates({ commit }: any, dates: [string, string]) {
			commit('setSelectedDates', dates)
		},
		setSelectedDeviceIds({ commit }: any, ids: string[]) {
			commit('setSelectedDeviceIds', ids)
		},
		setName({ commit }: any, name: string) {
			commit('setName', name)
		},
		setRoleId({ commit }: any, id: number) {
			commit('setRoleId', id)
		},
		setUnapproved({ commit }: any, val: boolean) {
			commit('setUnapproved', val)
		},
		setUsername({ commit }: any, name: string) {
			commit('setUsername', name)
		},
		setUserEnabled({ commit }: any, val: number) {
			commit('setUserEnabled', val)
		},
		setSelectedLevel({ commit }: any, val: number | null) {
			commit('setSelectedLevel', val)
		},
		setEmail({ commit }: any, email: string) {
			commit('setEmail', email)
		},
		setCustomerId({ commit }: any, id: number) {
			commit('setCustomerId', id)
		},
	},
	getters: {
		trustedFlows: (state: FiltersState) => {
			return state.trustedFlows
		},
		ignoredFlows: (state: FiltersState) => {
			return state.ignoredFlows
		},
		requests: (state: FiltersState) => {
			return state.requests
		},
		appliances: (state: FiltersState) => {
			return state.appliances
		},
		users: (state: FiltersState) => {
			return state.users
		},
		rules: (state: FiltersState) => {
			return state.rules
		},
		overrides: (state: FiltersState) => {
			return state.overrides
		},
		masterRules: (state: FiltersState) => {
			return state.masterRules
		},
		ips: (state: FiltersState) => {
			return state.ips
		},
	},
}
