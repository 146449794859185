import Vue from 'vue'
import Vuex from 'vuex'
import { app } from './app'
import { devices } from './devices'
import { dialogs } from './dialogs'
import { filters } from './filters'
import { buttons } from './buttons'
import { data } from './data'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		app,
		devices,
		filters,
		dialogs,
		buttons,
		data,
	},
})
