import { ActionContext, ActionTree } from 'vuex'
interface DataState {
	vlans: number[]
}

const Data: DataState = {
	vlans: [],
}

type Mutations = {
	[key: string]: any
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<DataState, DataState>, 'commit'>

interface Actions {
	setVlans({ commit }: AugmentedActionContext, vlans: number[]): void
}

const actions: ActionTree<DataState, DataState> & Actions = {
	setVlans({ commit }, vlans: number[]) {
		commit('setVlans', vlans)
	},
}

export const data = {
	namespaced: true,
	state: Data,
	mutations: {
		setVlans(state: DataState, vlans: number[]) {
			state.vlans = vlans
		},
	},
	actions,
	getters: {},
}
